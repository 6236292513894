import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Nav from '../components/NavSub'
import Layout from '../components/layout'
import Header from '../components/Header'
import headerPhoto from '../assets/images/banner-7.jpg'

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>About | Carien Zeeman Private Practice</title>
          <meta name="description" content="Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="info" className="main">
            <div className="content sub-section">
              <header className="major">
                <h2>About</h2>
              </header>
              <span className="image main">
                <img src={headerPhoto} alt="" />
              </span>
              <p>Carien is registered with the following bodies:</p>
              <li>South African Council for Social Services Professionals <a href='https://www.sacssp.co.za/' target='_blank' rel='noreferrer noopener'>(SACSSP)</a></li>
              <li>Board of Healthcare Funders <a href='http://bhfglobal.com/' target='_blank' rel='noreferrer noopener'>(BHF)</a></li>
              <li>South African Association for Social Workers in Private Practice <a href='https://www.saaswipp.co.za/' target='_blank' rel='noreferrer noopener'>(SAASWIPP)</a></li>
              <br></br>
              <p>She is also a member of the Association for Social Workers Implementing the Children's Act <a href='http://www.aswica.co.za/' target='_blank' rel='noreferrer noopener'>(ASWICA)</a>. ASWICA is a professional body for experienced social workers in private practice with particular expertise in working with children and their families within the Cape Provinces.</p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default About
